<template>
  <div class="wrapper">
    <div class="main-panel-full">
      <div class="row">
        <div class="col-12">
          <dashboard-content></dashboard-content>
        </div>
      </div>

    </div>
  </div>
</template>
<style lang="scss">
@import 'src/assets/sass/etl-dashboard.scss';
</style>
<script>
  import DashboardContent from './Content.vue'
  import initPluginViewMixin from '../mixins/initPluginViewMixin'

  export default {
    components: {
      DashboardContent
    },
    mixins:[initPluginViewMixin]
  }
</script>
