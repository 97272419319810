var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "main-panel-full" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [_c("dashboard-content")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }